const colors = [
    '#6080F0',
    '#65D0E5',
    '#63E0B7',
    '#FFB870',
    '#F0788B',
    '#9999FF',
    '#C090F0',
    '#B8BCCC',
    '#879FF5',
    '#8EE4F5',
    '#8BF0CE',
    '#FFCA94',
    '#F593A2',
    '#B2B2FF',
    '#D0ABF5',
    '#D8D9E5',
    '#AFC0FA',
    '#ADF2FF',
    '#A6F5DB',
    '#FFDBB8',
    '#FAB9C3',
    '#CCCCFF',
    '#E1C8FA',
    '#E6E9F2'
]
const barColor = ['#7FA6FA']

const tooltipConfig = {
    backgroundColor: 'rgba(72,84,101, 0.9)',
    padding: 12,
}

const xAxisLineConfig = {
    name: '',
    nameTextStyle: {
        color: '#666',
        padding: [28, 0, 0, 10],
    },
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLine: {
        lineStyle: {
            color: '#eee',
        },
    },
    axisLabel: {
        textStyle: {
            color: '#666',
        },
        showMaxLabel: true,
    },
}

const yAxisLineConfig = {
    name: '',
    nameTextStyle: {
        color: '#666',
        padding: [0, 18, 0, 0],
    },
    splitLine: {
        show: true,
        lineStyle: {
            type: 'dotted',
        },
    },
    axisLine: {
        lineStyle: {
            color: '#eee',
        },
    },
    axisLabel: {
        textStyle: {
            color: '#666',
        },
    },
    axisTick: {
        lineStyle: {
            color: '#eee',
        },
    },
}

export default {
    colors,
    barColor,
    tooltipConfig,
    xAxisLineConfig,
    yAxisLineConfig,
}
