import { createStore } from 'vuex'
import profile from './modules/profile'
import userInfo from './modules/userInfo'
import stepInfo from './modules/stepInfo'

export default createStore({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        profile,
        userInfo,
        stepInfo
    }
})
