import request from "@/common/axios";

/**
 * 获取登录用户信息
 * @returns
 */
export function getUserInfo() {
    return request({
        // url: '/api/vrp/v1/doctor',
        url: '/v1/actions/login_info',
        method: 'GET'
    })
}
