import request from "@/common/axios";

/**
 * 获取教师列表数据
 * @param {Object} data
 * @param {number} data.gender - 性别 0女1男
 * @param {string} data.constellation - 星座
 * @param {string} data.province - 省份
 * @param {string} data.city - 城市
 * @param {string} data.district - 地区
 * @param {string} data.level - 级别
 * @param {string} data.state - 状态
 * @param {number} data.is_public - 是否公开
 * @param {number} data.is_recommended - 是否推荐
 * @param {number} data.page - 页码
 * @param {number} data.per_page - 每页记录条数
 * @param {string} data.order_by - 排序
 * @param {string} data.fuzzy_query - 模糊查询内容，不传默认全部查询
 * @returns {*}
 */
export function getTeacherList(data) {
    return request({
        url: '/v1/teacher',
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 获取教师数据
 * @param {string} ulid - 教师id
 * @returns {*}
 */
export function getTeacher(ulid) {
    return request({
        url: '/v1/teacher/' + ulid,
        method: 'GET',
    })
}

/**
 * 获取教师数据
 * @param {string} ulid - 教师id
 * @param {object} data
 * @param {number} data.is_recommended
 * @param {number} data.status
 * @param {number} data.level
 * @returns {*}
 */
export function updateTeacher(ulid, data) {
    return request({
        url: '/v1/teacher/' + ulid,
        method: 'PATCH',
        data
    })
}
