import request from "@/common/axios";

/**
 * 获取匹配关系列表数据
 * @param {object} data
 * @param {string} data.extra_fields - 接口需要的额外字段逗号分隔,parent,teacher,child
 * @param {string} data.parent_ulid - 家长ulid
 * @param {string} data.child_ulid - 家长ulid
 * @param {string} data.teacher_ulid - 教师ulid
 * @param {string} data.state - 教师ulid
 * @param {number} data.page - 页码
 * @param {number} data.per_page - 每页记录条数
 * @param {string} data.order_by - 排序
 * @param {string} data.fuzzy_query - 模糊查询内容，不传默认全部查询
 * @returns {*}
 */
export function getRelationList(data) {
    return request({
        url: '/v1/relation',
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 获取匹配关系数据
 * @param {string} ulid - ulid
 * @param {object} data
 * @param {string} data.extra_fields - detail
 * @returns {*}
 */
export function getRelation(ulid, data) {
    return request({
        url: '/v1/relation/' + ulid,
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 创建匹配关系数据
 * @param {object} data
 * @param {string} data.parent_ulid - 家长
 * @param {string} data.child_ulid - 孩子
 * @param {string} data.teacher_ulid - 老师
 * @returns {*}
 */
export function createRelation(data) {
    return request({
        url: '/v1/relation',
        method: 'POST',
        data: data,
    })
}

/**
 * 更新匹配关系数据
 * @param {string} ulid - 孩子id
 * @param {object} data
 * @param {number} data.state
 * @returns {*}
 */
export function updateRelation(ulid, data) {
    return request({
        url: '/v1/relation/' + ulid,
        method: 'PATCH',
        data
    })
}
