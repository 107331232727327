import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue' // 引入element-plus中的icon
import 'assets/styles/main.scss'
import 'assets/styles/font/font.css'
// import 'assets/styles/font/iconfont.css'
import 'assets/styles/font/sd-icons/style.css'
import axios from 'axios'
import router from './router'
import store from './store'
import { getUserInfo } from '@/services/user-info'
import { WHO } from '@/store/types.js'
import _ from 'lodash'

// createApp(App).use(store).use(router).mount('#app')
// eslint-disable-next-line
console.log(process.env)
const app = createApp(App)

// 全局引入Element的ICON库
for (const name in ElementPlusIconsVue) {
    app.component(name, ElementPlusIconsVue[name])
}
app.config.globalProperties.$http = axios

app.use(ElementPlus, {locale})
app.use(store)
app.use(router)
app.mixin({
    // 需求：在任何组件 dom 准备好的时候打印一句话
    mounted() {},
    methods: {
        redirect(err, errorMsg = '') {
            // cookie过期自动跳转登录页
            if (err.code === 'unauthorized' && this.$route.name !== 'login') {
                this.$router.push({
                    name: 'login',
                    query: {
                        redirectUrl: this.$route.fullPath,
                        t: new Date().valueOf(),
                    }
                })
            } else {
                this.$message.error(err.message || errorMsg || '网络异常')
                return false
            }
        },
    }
});

getUserInfo().then(resp => {
    console.log('main.getUserInfo', resp)
    store.commit(WHO.SET_WHO, resp)
}).catch(err => {
    console.log("main.getUserInfo", err)
}).finally(() => {
    app.mount('#app')
})
