import axios from 'axios'

const isNewVersion = () => {
    let url = `${window.location.origin}${window.location.pathname}version.json?t=${new Date().getTime()}`

    axios.get(url).then(res => {
        if(res.status === 200) {
            let vueVersion = res.data.version || '0.1.0'
            const localVueVersion = localStorage.getItem('vueVersion')

            localStorage.setItem('vueVersion', vueVersion)
            if (!localVueVersion || (localVueVersion && localVueVersion !== vueVersion)) {
                // if (localVueVersion) {
                //     alert('监测到新版本，请点击确认后刷新页面')
                // }
                window.location.reload(true)
                return false
            } else {
                return true
            }
        }
    })
}

export default {
    isNewVersion
}