import request from "@/common/axios";

/**
 * 获取答疑列表数据
 * @param {object} data
 * @param {string} data.extra_fields - 接口需要的额外字段逗号分隔,teacher,expert
 * @param {string} data.question_ulid - 所属问题ulid
 * @param {string} data.q_ulid - 教师ulid
 * @param {string} data.a_ulid - 专家ulid
 * @param {string} data.keyword - title关键字模糊匹配
 * @param {number} data.page - 页码
 * @param {number} data.per_page - 每页记录条数
 * @param {string} data.order_by - 排序
 * @param {string} data.fuzzy_query - 模糊查询内容，不传默认全部查询
 * @returns {*}
 */
export function getQaList(data) {
    return request({
        url: '/v1/qa',
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 获取答疑数据
 * @param {string} ulid - ulid
 * @param {object} data
 * @param {string} data.extra_fields - detail
 * @returns {*}
 */
export function getQa(ulid, data) {
    return request({
        url: '/v1/qa/' + ulid,
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 创建答疑数据
 * @param {object} data
 * @param {string} data.title - 标题
 * @param {string} data.content - 内容
 * @returns {*}
 */
export function createQa(data) {
    return request({
        url: '/v1/qa',
        method: 'POST',
        data: data,
    })
}

/**
 * 更新答疑数据
 * @param {string} ulid
 * @param {object} data
 * @param {string} data.answer
 * @param {number} data.status
 * @returns {*}
 */
export function updateQa(ulid, data) {
    return request({
        url: '/v1/qa/' + ulid,
        method: 'PATCH',
        data
    })
}
