import axios from 'axios';
import router from "@/router";

// const url = location.host.replace(location.hash, '')
// const url = `${location.hostname}`
// const baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_RUL + process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API
const baseUrl = process.env.VUE_APP_BASE_API

console.log("baseurl", baseUrl);
const request = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 5000, // 超时设置为5秒
});

request.interceptors.request.use(function (config) {
    // 在发送请求之前添加身份验证标头
    // config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    // config.headers.Username = localStorage.getItem('username');
    return config;
}, function (error) {
    // 对请求错误做些什么
    console.log('request.interceptors.request.use', error);
    return Promise.reject(error);
});


request.interceptors.response.use(function (response) {
    // 对响应数据做些什么
    const responseData = response.data;

    // if (responseData.code !== 0) {
    //     // const error = new Error(responseData.message || '请求失败');

    //     // error.response = response;
    //     // throw error;
    //     return Promise.reject(responseData)
    // }
    // return responseData.data;
    return responseData
}, function (error) {
    // 对响应错误做些什么
    // eslint-disable-next-line no-undefined
    if (error.response === undefined) {
        console.error(`http请求出错==>${error}`);
    }
    // error.message = `${error.response.status}:  ${error.response.data.error.message}!`;
    error.code = error.response.data.error.code;
    error.message = error.response.data.error.message;
    // let method = error.response?.config?.method || '';
    //
    // if (method.toLowerCase() !== 'get') {
    //     Message.creators.error(error.message);
    // }
    if (error.response.status === 401) {
        router.replace('/login')
        // storage.removeCookieStorage('useLogin')
    }
    console.log('request.interceptors.request.use2', error);
    return Promise.reject(error);
    // let status = ''
    //
    // if (error.request) {
    //     status = error.request
    // } else if (error.response) {
    //     status = error.response
    // }
    //
    // if (status) {
    //     switch (status.status) {
    //     case 400:
    //         error.message = '请求错误（400）'
    //         break
    //     case 401:
    //         error.message = '未授权，请重新登录（401）'
    //         router.replace('/login')
    //         break
    //     case 403:
    //         error.message = '拒绝访问（403）'
    //         break
    //     case 404:
    //         error.message = '请求出错（404）'
    //         break
    //     case 408:
    //         error.message = '请求超时（408）'
    //         break
    //     case 500:
    //         error.message = '服务器错误（500）'
    //         break
    //     case 501:
    //         error.message = '服务未实现（501）'
    //         break
    //     case 502:
    //         error.message = '网络错误（502）'
    //         break
    //     case 503:
    //         error.message = '服务不可用（503）'
    //         break
    //     case 504:
    //         error.message = '网络超时（504）'
    //         break
    //     case 505:
    //         error.message = 'HTTP版本不支持（505）'
    //         break
    //     default:
    //         error.message = `连接出错（${error.response.status}）`
    //         break
    //     }
    // } else {
    //     error.message = '连接服务器失败'
    // }
    // return Promise.reject(error)
});

export default request;
