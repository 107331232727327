import request from "@/common/axios";

/**
 * 获取培训列表数据
 * @param {object} data
 * @param {string} data.extra_fields - 接口需要的额外字段逗号分隔,parent,teacher,child
 * @param {string} data.child_name - 通过孩子姓名搜索
 * @param {string} data.parent_ulid - 家长ulid
 * @param {string} data.child_ulid - 家长ulid
 * @param {string} data.teacher_ulid - 教师ulid
 * @param {number} data.page - 页码
 * @param {number} data.per_page - 每页记录条数
 * @param {string} data.order_by - 排序
 * @param {string} data.fuzzy_query - 模糊查询内容，不传默认全部查询
 * @returns {*}
 */
export function getWorkList(data) {
    return request({
        url: '/v1/assignment',
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 获取培训数据
 * @param {string} ulid - ulid
 * @param {object} data
 * @param {string} data.extra_fields - detail
 * @returns {*}
 */
export function getWork(ulid, data) {
    return request({
        url: '/v1/assignment/' + ulid,
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 创建培训数据
 * @param {object} data
 * @param {string} data.name - 名称
 * @param {string} data.video - 培训视频
 * @param {string} data.photo - 图片地址
 * @param {string} data.content - 培训内容记录
 * @param {string} data.child_ulid - 孩子ulid
 * @returns {*}
 */
export function createWork(data) {
    return request({
        url: '/v1/assignment',
        method: 'POST',
        data: data,
    })
}

/**
 * 更新培训数据
 * @param {string} ulid
 * @param {object} data
 * @param {number} data.status
 * @returns {*}
 */
export function updateWork(ulid, data) {
    return request({
        url: '/v1/assignment/' + ulid,
        method: 'PATCH',
        data
    })
}
