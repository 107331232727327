// 障碍类型
export const disabilityTypeList = [
    {
        key: 'autism',
        display: '孤独症'
    },
    {
        key: 'development_delay',
        display: '发育迟缓'
    },
    {
        key: 'aphasia',
        display: '智力障碍'
    },
    {
        key: 'dyslexia',
        display: '语言障碍'
    },
    {
        key: 'other',
        display: '其他'
    }
]

export const disabilityTypeMap = {
    'autism': {
        key: 'autism',
        display: '孤独症'
    },
    'development_delay': {
        key: 'development_delay',
        display: '发育迟缓'
    },
    'aphasia': {
        key: 'aphasia',
        display: '智力障碍'
    },
    'dyslexia': {
        key: 'dyslexia',
        display: '语言障碍'
    },
    'other': {
        key: 'other',
        display: '其他'
    }
}

// 融合方式
export const inclusionTypeList = [
    {
        key: 'half_day',
        display: '半日'
    },
    {
        key: 'full_day',
        display: '全日'
    }
]

export const inclusionTypeMap = {
    'half_day': {
        key: 'half_day',
        display: '半日'
    },
    'full_day': {
        key: 'full_day',
        display: '全日'
    }
}
