/* eslint-disable no-unused-vars */
import _ from 'lodash'
import { getUserInfo } from '@/services/user-info'
import { WHO } from '@/store/types'

export default {
    state: {
        userId: window.localStorage.getItem('vr.userId') || 0,
        isLogin: window.localStorage.getItem('vr.isLogin') || false,
        who: JSON.parse(window.localStorage.getItem('vr.userInfo')) || {},
        hospital: ''
    },
    getters: {
        userId(state) {
            return state.userId
        },
        isLogin(state) {
            return state.isLogin
        },
        getWho(state) {
            return state.who
        },
        hospital(state) {
            return state.hospital
        },
        therapyTypeList(state) {
            return state.who.therapy_types ? state.who.therapy_types : []
        }
    },
    mutations: {
        [WHO.SET_USERID](state, userId) {
            state.userId = userId
            window.localStorage.setItem('vr.userId', userId)
        },
        [WHO.SET_WHO](state, who) {
            state.who = who
            window.localStorage.setItem('vr.userInfo', JSON.stringify(who))
            state.userId = _.get(who, 'user_id')
            state.hospital = _.get(who, 'hospital_id')
            // return who
        },
        [WHO.SET_LOGIN](state, isLogin) {
            state.isLogin = isLogin
            window.localStorage.setItem('vr.isLogin', isLogin)
        }
    },
    actions: {
        async [WHO.FETCH_WHO](store) {
            const result = await getUserInfo()

            if (result.code === 0) {
                store.commit(WHO.SET_WHO, result.data)
            } else {
                store.commit(WHO.SET_WHO, '{}')
            }
        },
        [WHO.CLEAR_WHO](store) {
            store.commit(WHO.SET_USERID, 0)
            store.commit(WHO.SET_WHO, '{}')
            store.commit(WHO.SET_LOGIN, false)
        }
    },
}
