import request from "@/common/axios";

/**
 * 登录
 * @param {string} userName - 登录账号id
 * @param {string} password - 登录密码
 * @returns
 */
export function login(userName, password) {
    return request({
        url: '/v1/actions/login',
        method: 'POST',
        data: {
            username: userName,
            password: password
        }
    })
}

export function updatePassword(password) {
    return request({
        url: '/v1/actions/update_password',
        method: 'PATCH',
        data: {
            password: password,
        }
    })
}

/**
 * 退出
 * @returns
 */
export function logout() {
    return request({
        url: '/v1/actions/logout',
        method: 'DELETE',
        data: {}
    })
}
