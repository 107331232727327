// profile store types
export const PROFILE = {
    UPDATE_NARROW: 'PROFILE_UPDATE_NARROW',
    UPDATE_PROFILE: 'PROFILE_UPDATE_PROFILE',
    CLEAR_PROFILE: 'PROFILE_CLEAR_PROFILE'
}

export const WHO = {
    FETCH_WHO: 'FETCH_WHO',
    SET_USERID: 'SET_USERID',
    SET_WHO: 'SET_WHO',
    SET_LOGIN: 'SET_LOGIN',
    CLEAR_WHO: 'CLEAR_WHO',
}

export const STEPINFO = {
    INIT_STEP: 'INIT_STEP',
    UPDATE_STEP: 'UPDATE_STEP',
    REMOVE_STEP: 'REMOVE_STEP',
    CLEAR_STEP: 'CLEAR_STEP',
}