import request from "@/common/axios";

/**
 * 获取家长列表数据
 * @param {object} data
 * @param {number} data.gender - 性别 0女1男
 * @param {string} data.constellation - 星座
 * @param {string} data.province - 省份
 * @param {string} data.city - 城市
 * @param {string} data.district - 地区
 * @param {number} data.page - 页码
 * @param {number} data.per_page - 每页记录条数
 * @param {string} data.extra_fields - children
 * @param {string} data.order_by - 排序
 * @param {string} data.fuzzy_query - 模糊查询内容，不传默认全部查询
 * @returns {*}
 */
export function getParentList(data) {
    return request({
        url: '/v1/parent',
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 获取家长数据
 * @param {string} ulid - 家长id
 * @param {object} data
 * @param {string} data.extra_fields - 家长id
 * @returns {*}
 */
export function getParent(ulid, data) {
    return request({
        url: '/v1/parent/' + ulid,
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 获取家长数据
 * @param {string} ulid - 家长id
 * @param {object} data
 * @param {number} data.is_recommended
 * @param {number} data.status
 * @returns {*}
 */
export function updateParent(ulid, data) {
    return request({
        url: '/v1/parent/' + ulid,
        method: 'PATCH',
        data
    })
}
