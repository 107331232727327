export const profileMap = {
    // 机构分配
    ResourceOrgAssign: "org_assign",
    // 管理员账户
    ResourceAdminAccount: "admin_account",
    // 高级用户
    ResourceAdvancedUser: "advanced_user",
    // 普通用户
    ResourceNormalUser: "normal_user",
    // 患者
    ResourcePatient: "patient",
    // 训练方案模板
    ResourceTreatmentPlanTemplate: "treatment_plan_template",
    // 训练方案
    ResourceTreatmentPlan: "treatment_plan",
    // 训练
    ResourceTreatment: "treatment",
    // 量表评估
    ResourceQuestionnaireAssessment: "questionnaire_assessment",
    // 心情打分设置
    ResourceMoodScoreSetting: "mood_score_setting",
}

export const operateMap = {
    // 读
    PermissionRead: "read",
    // 写
    PermissionWrite: "write",
    // 删除
    PermissionDelete: "delete",
    // 执行
    PermissionExec: "exec",
    // 分配
    PermissionAlloc: "alloc",
}