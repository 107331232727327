import { PROFILE } from '../types'
import { profileMap, operateMap} from '@/config/profile'

const LOCAL_KEY_HOME_NARROW = 'xr.narrow'
const LOCAL_KEY_HOME_PROFILE= 'xr.profile'

export default {
    state: {
        homeNarrow: window.localStorage.getItem(LOCAL_KEY_HOME_NARROW) === '1',
        profile: window.localStorage.getItem(LOCAL_KEY_HOME_PROFILE) ? JSON.parse(window.localStorage.getItem(LOCAL_KEY_HOME_PROFILE)) : {},
    },
    getters: {
        homeNarrow: state => state.homeNarrow, // 左侧栏展开或折叠状态：1为折叠 0为展开
        profile: state => state.profile,
    },
    mutations: {
        [PROFILE.UPDATE_NARROW] (state, { narrow }) {
            state.homeNarrow = narrow ? 1 : 0
            window.localStorage.setItem(LOCAL_KEY_HOME_NARROW, narrow ? 1 : 0)
        },
        [PROFILE.UPDATE_PROFILE] (state, permission) {
            let profile = {}

            if (permission.length > 0) {
                permission.map(item => {
                    // 新增/编辑/删除患者
                    if (item.resource === profileMap.ResourcePatient) {
                        if (item.permission === operateMap.PermissionWrite) {
                            profile['add_patient'] = true
                            profile['update_patient'] = true
                            profile['delete_patient'] = true
                        }
                    } else if (item.resource === profileMap.ResourceTreatmentPlanTemplate) {
                        // 配置训练方案模板（新增/编辑训练模块内容)
                        if (item.permission === operateMap.PermissionWrite) {
                            profile['update_treat_plan_content'] = true
                        }
                    } else if (item.resource === profileMap.ResourceTreatmentPlan) {
                        // 更改训练方案
                        if (item.permission === operateMap.PermissionWrite) {
                            profile['update_treat_plan'] = true
                        } else if (item.permission === operateMap.PermissionAlloc) {
                            // 分配训练方案
                            profile['alloc_treat_plan'] = true
                        }
                    } else if (item.resource === profileMap.ResourceTreatment) {
                        // 执行训练
                        if (item.permission === operateMap.PermissionExec) {
                            profile['begin_treat'] = true
                            profile['continue_treat'] = true
                            profile['pause_treat'] = true
                            profile['restart_treat'] = true
                            profile['stop_treat'] = true
                            profile['print_report'] = true
                        }
                    } else if (item.resource === profileMap.ResourceQuestionnaireAssessment) {
                        // 执行量表评估
                        if (item.permission === operateMap.PermissionWrite) {
                            profile['add_questionnaire'] = true
                            profile['update_questionnaire'] = true
                        } else if (item.permission === operateMap.PermissionExec) {
                            profile['exec_questionnaire'] = true
                        }
                    } else if (item.resource === profileMap.ResourceMoodScoreSetting) {
                        // 心情打分
                        if (item.permission === operateMap.PermissionWrite) {
                            profile['mood_score'] = true
                        }
                    }
                })
                state.profile = profile || {}
                window.localStorage.setItem(LOCAL_KEY_HOME_PROFILE, JSON.stringify(profile) || '{}')
            }
        },
        [PROFILE.CLEAR_PROFILE] (state) {
            state.profile = {}
            window.localStorage.setItem(LOCAL_KEY_HOME_PROFILE, '{}')
        }
    }
}
