export const statusList = [
    {
        key: 0,
        display: '已下架'
    },
    {
        key: 1,
        display: '已上线'
    }
]

export const statusMap = {
    0: {
        key: 0,
        display: '已下架',
        className: 'y-color-R1'
    },
    1: {
        key: 1,
        display: '已上线',
        className: 'y-color-T2'
    }
}