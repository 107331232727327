import request from "@/common/axios";

/**
 * 获取孩子列表数据
 * @param {object} data
 * @param {number} data.extra_fields - 接口需要的额外字段逗号分隔,parent,teacher
 * @param {number} data.parent_ulid - 家长ulid
 * @param {number} data.parent_ulid - 家长ulid
 * @param {number} data.teacher_ulid - 教师ulid
 * @param {number} data.gender - 性别 0女1男
 * @param {number} data.has_epilepsy - 癫痫0无1有
 * @param {number} data.teacher_level - 需求教师的级别junior/senior/expert
 * @param {number} data.disability_type - 障碍类型
 * @param {number} data.professional_rehabilitation - 是否专业康复
 * @param {number} data.school_inclusion - 是否家校融合
 * @param {number} data.inclusion_type - 家校融合方式half_day/full_day
 * @param {string} data.constellation - 星座
 * @param {string} data.province - 省份
 * @param {string} data.city - 城市
 * @param {string} data.district - 地区
 * @param {number} data.page - 页码
 * @param {number} data.per_page - 每页记录条数
 * @param {string} data.order_by - 排序
 * @param {string} data.fuzzy_query - 模糊查询内容，不传默认全部查询
 * @returns {*}
 */
export function getChildList(data) {
    return request({
        url: '/v1/child',
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 获取孩子数据
 * @param {string} ulid - 家长id
 * @param {object} data
 * @param {string} data.extra_fields - 家长id
 * @returns {*}
 */
export function getChild(ulid, data) {
    return request({
        url: '/v1/child/' + ulid,
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 更新孩子数据
 * @param {string} ulid - 孩子id
 * @param {object} data
 * @param {number} data.status
 * @returns {*}
 */
export function updateChild(ulid, data) {
    return request({
        url: '/v1/child/' + ulid,
        method: 'PATCH',
        data
    })
}
