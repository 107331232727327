<template>
  <router-view />
</template>
<script setup>
import { onMounted } from 'vue'

onMounted(() => {
    // document.body.style.setProperty('--el-color-primary', '#00B2C2')
    // document.body.style.setProperty('--el-color-primary-light-3', '#34C7D1')
    // document.body.style.setProperty('--el-color-primary-light-5', '#D3F2F5')
    // eslint-disable-next-line
    console.log('build test')
})

const debounce = (fn, delay) => {
    let timer = null;

    return function () {
        let context = this;

        let args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const resizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends resizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};
</script>
<style lang="scss">
// @import url('//at.alicdn.com/t/c/font_4035614_o6ai3aplmwj.css');
@import '@/assets/styles/variables.scss';


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
