import _ from 'lodash'
import { STEPINFO } from '../types'

const LOCAL_KEY_STEPS = 'xr.stepInfo'

export default {
    state: {
        stepInfo: JSON.parse(window.sessionStorage.getItem(LOCAL_KEY_STEPS)) || {}
    },
    getters: {
        stepInfo: state => state.stepInfo
    },
    mutations: {
        [STEPINFO.INIT_STEP] (state, { patientId, stepList }) {
            state.stepInfo[patientId] = stepList
            window.sessionStorage.setItem(LOCAL_KEY_STEPS, JSON.stringify(state.stepInfo))
        },
        [STEPINFO.UPDATE_STEP](state, {patientId, stepList}) {
            state.stepInfo[patientId] = stepList
        },
        [STEPINFO.REMOVE_STEP](state, {patientId}) {
            let steps = {}

            _.map(state.stepInfo, (item, key) => {
                if (key !== patientId) {
                    steps[key] = item
                }
            })
            state.stepInfo = steps
        },
        [STEPINFO.CLEAR_STEP](state) {
            state.stepInfo = {}
            window.sessionStorage.setItem(LOCAL_KEY_STEPS, '{}')
        },
    }
}