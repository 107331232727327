import request from "@/common/axios";

/**
 * 获取教师列表数据
 * @param {{}} data
 * @param {number} data.ulid - id
 * @param {string} data.username - 登录名
 * @param {string} data.phone - 手机号
 * @param {string} data.displsy_name - 展示名
 * @param {string} data.extra_fields - 扩展字段
 * @param {string} data.fuzzy_query - 模糊查询内容，不传默认全部查询
 * @param {number} data.page - 当前页数
 * @param {number} data.per_page - 每页条数
 * @returns {*}
 */
export function getExpertList(data) {
    return request({
        url: '/v1/admin/expert',
        method: 'GET',
        params: {
            ...data
        }
    })
}


/**
 * 新增专家
 * @param {object} data
 * @param {string} data.display_name - 用户姓名
 * @param {string} data.phone - 用户手机号
 * @param {string} data.username - 登录名称
 * @param {string} data.password - 登录密码
 * @returns {*}
 */
export function createExpert(data) {
    return request({
        url: '/v1/admin/expert',
        method: 'POST',
        data
    })
}

/**
 * 新增专家
 * @param {string} uid - 用户姓名
 * @param {object} data
 * @param {string} data.display_name - 用户姓名
 * @param {string} data.phone - 用户手机号
 * @param {string} data.username - 登录名称
 * @param {string} data.password - 登录密码
 * @param {string} data.status - 状态 -1删除
 * @returns {*}
 */
export function updateExpert(uid, data) {
    return request({
        url: '/v1/admin/expert/' + uid,
        method: 'PATCH',
        data
    })
}
/**
 * 新增专家
 * @param {string} uid - 用户姓名
 * @returns {*}
 */
export function getExpert(uid) {
    return request({
        url: '/v1/admin/expert/' + uid,
        method: 'GET',
    })
}
