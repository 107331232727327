import request from "@/common/axios";

/**
 * 获取评论列表数据
 * @param {Object} data
 * @param {number} data.keyword - 通过关键字搜索标题，支持模糊搜索
 * @param {string} data.target_type - 评论对象类型 teacher/assignment
 * @param {string} data.target_ulid - 评论对象ulid
 * @param {string} data.status - -1删除0隐藏1正常
 * @param {string} data.user_ulid - 评论人ulid
 * @param {number} data.page - 页码
 * @param {number} data.per_page - 每页记录条数
 * @param {string} data.order_by - 排序
 * @returns {*}
 */
export function getCommentList(data) {
    return request({
        url: '/v1/comment',
        method: 'GET',
        params: {
            ...data
        }
    })
}

/**
 * 获取评论数据
 * @param {string} ulid - 评论ulid
 * @returns {*}
 */
export function getComment(ulid) {
    return request({
        url: '/v1/comment/' + ulid,
        method: 'GET',
    })
}

/**
 * 修改数据
 * @param {string} ulid - 评论ulid
 * @param {object} data
 * @param {number} data.is_recommended
 * @param {number} data.status
 * @returns {*}
 */
export function updateComment(ulid, data) {
    return request({
        url: '/v1/comment/' + ulid,
        method: 'PATCH',
        data
    })
}
