import { cloneDeep, isNull, every, each, isPlainObject, isObject } from 'lodash'
// import moment from 'moment'

export function urlMatcher(url, params = {}) {
    return url.replace(/\/:([^/]+)/g, (match, g1) => {
        return params[g1] ? '/' + params[g1] : ''
    })
}

// 清理无用参数
export function pruneParams(params, visible) {
    let newParams = cloneDeep(params)

    each(newParams, (v, i) => {
        if (newParams[i] === '' || isNull(newParams[i]) || newParams[i] === '_all_' || (isPlainObject(visible) && visible[i] === false)) {
            delete newParams[i]
        }
    })
    return newParams
}

// 随机数
export function randomCode() {
    return (+new Date()).toString(32) + '-' + Math.random().toString(32).slice(2)
}

// 压缩图片并转为jpeg
export function convert2JPEG(img, isCompress = false) {
    // 图片质量 待确认? 120x120
    const canvas = document.createElement('canvas')

    if (isCompress) {
        canvas.width = 200
        canvas.height = 200 * (img.height / img.width)
        const ctx = canvas.getContext('2d')

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
    } else {
        canvas.width = Math.min(img.width, 2500)
        canvas.height = canvas.width * img.height / img.width
        const ctx = canvas.getContext('2d')

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
    }
    return canvas.toDataURL('image/jpeg', 0.9)
}

// 获取图片base64
export function getBase64(fileBlob, isCompress = false) {
    return new Promise((resolve, reject) => {
        const fr = new FileReader()

        fr.addEventListener('load', () => {
            if (!isCompress) {
                resolve(fr.result)
            } else {
                const img = new Image()

                img.onload = () => {
                    resolve(this.convert2JPEG(img, isCompress))
                }
                img.onerror = (err) => {
                    resolve(err)
                }
                img.src = fr.result
            }
        })

        fr.addEventListener('error', () => {
            reject(fr.error)
        })

        fr.readAsDataURL(fileBlob)
    })
}

// 获取base64位图片的宽高比
export function getNaturalWidth(src) {
    var image = new Image()

    image.src = src
    const naturalWidth = image.width
    const natureHeight = image.height

    return naturalWidth / natureHeight
}

// 补齐元素
export function padDataTo(data = [], number = 0) {
    if (!data.length) {
        return data
    }

    every(Array(number).fill(0), v => {
        if (data.length % number === 0) {
            return false
        }
        data.push({ _placeholder: true })
        return true
    })
    return data
}

//  秒数转化为时分秒
export function formatSeconds(time, hasUnit = false) {
    let newTime
    let hour
    let minute
    let seconds

    if (time >= 3600) {
        hour = parseInt(time / 3600) < 10 ?
            '0' + parseInt(time / 3600) :
            parseInt(time / 3600)
        minute = parseInt(time / 60 % 60) < 10 ?
            '0' + parseInt(time / 60 % 60) :
            parseInt(time / 60 % 60)
        seconds = time % 60 < 10 ? '0' + parseInt(time % 60) : parseInt(time % 60)
        if (hasUnit) {
            newTime = `${hour}小时`
            if (minute !== '00') {
                newTime += `${minute}分钟`
            }
            if (seconds !== '00') {
                newTime += `${seconds}秒`
            }
        } else {
            newTime = `${hour}:${minute}:${seconds}`
        }
    } else if (time >= 60 && time < 3600) {
        minute = parseInt(time / 60) < 10 ?
            '0' + parseInt(time / 60) :
            parseInt(time / 60)
        seconds = time % 60 < 10 ? '0' + parseInt(time % 60) : parseInt(time % 60)
        if (hasUnit) {
            newTime = `${minute}分钟`
            if (seconds !== '00') {
                newTime += `${seconds}秒`
            }
        } else {
            newTime = `00:${minute}:${seconds}`
        }
    } else if (time < 60 ) {
        seconds = time < 10 ? '0' + parseInt(time) : parseInt(time)
        newTime = hasUnit ? `${seconds}秒` : `00:00:${seconds}`
    }
    return newTime
}

// // 日期到 23:59:59秒
// export function ceilDate(date) {
//     return date ? +moment(moment(date).format('YYYY-MM-DD')).toDate() + 86400000 - 1000 : ''
// }

// // 日期到 00:00:00秒
// export function floorDate(date) {
//     return date ? +moment(moment(date).format('YYYY-MM-DD')).toDate() : ''
// }

//取得字符串真实长度
export function getStringTrueLength(str) {
  return String(str).replace(/[^\x00-\xff]/g, 'xx').length // eslint-disable-line
}

//验证字符串是否是二代18位身份证
export function isIdcard(value) {
    if (value === null || value === '') {
        return false
    }
    if (getStringTrueLength(value) !== 18) {
        return false
    }

    const jqyz = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    const vcode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

    let jssum = 0

    for (let i = 0; i < getStringTrueLength(value) - 1; i++) {
        jssum += Number(value[i]) * jqyz[i]
    }

    return (value[17]) === (vcode[jssum % 11])
}

// 预加载图片
export function preloadImages(images = []) {
    if (isObject(images)) {
        images = [images]
    }
    return Promise.all(images.map(v => {
        return new Promise((resolve, reject) => {
            const img = new Image()

            img.onload = () => {
                resolve({ ok: true, img: img, ...v })
            }

            img.onerror = () => {
                resolve({ ok: false, img: img, ...v })
            }
            img.src = v.url
        })
    }))
}

let textCanvas = null
const DEFAULT_FONT = `14px -apple-system, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC",
 "Microsoft YaHei", "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", sans-serif`

/**
 * 获取指定字体下文本渲染出来的宽度
 * @param {string} str 文本内容
 * @param {string} font 字体
 * @returns {number}
 */
export function getRealLength(str, font = DEFAULT_FONT) {
    if (!textCanvas) {
        textCanvas = document.createElement('canvas')
    }
    const ctx = textCanvas.getContext('2d')

    ctx.font = font
    return str ? ctx.measureText(str).width : 0
}

// 下载base64数据到本地
export function downloadBase64File(base64Data, fileName) {
    // 将base64编码的数据转换为Blob对象
    let byteCharacters = atob(base64Data.split(',')[1])
    let byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        let slice = byteCharacters.slice(offset, offset+512)
        let byteNumbers = new Array(slice.length)

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }
        let byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }
    let blob = new Blob(byteArrays, {type: 'application/octet-stream'})

    // 创建<a>元素和URL对象后实现文件下载
    let url = URL.createObjectURL(blob)
    let a = document.createElement('a')

    a.style.display = 'none'
    a.href = url;
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    // 清理创建的对象
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
}

export function calculateAge(birthday) {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}